<template>
    <Layout header-class="header-transparent" :show-newsletter="false" :show-cta="false">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 height-850 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/logo/bg.png`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner pt--80 text-center">
                            <div>
                                <h3 class="rn-sub-badge" data-aos="fade-up" data-aos-delay="100">
                                    <span class="theme-gradient">公司详细介绍</span>
                                </h3>
                            </div>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                                物联网医疗 <br/> + <br/> 新零售
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <!-- Start Service Area -->
        <div class="service-area rn-section-gapTop">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="content" data-aos="fade-up">
                            <h3 class="title">我们致力于打造<br/>全国领先的医疗销售平台！</h3>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <p class="mb--10" data-aos="fade-up">
                            我们是一家以物联网自动化技术+大数据为基础<br/>
                            以患者为核心-医疗服务为抓手<br/>
                            提供全场景智慧医药终端生态链的综合服务商
                        </p>
                    </div>
                </div>
                <Tab :tab-data="tabData"/>
            </div>
        </div>
        <!-- End Service Area -->

        <!-- Start Brand Area -->
        <!-- <div class="rwt-brand-area pb--60 pt--30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Brand :brand-list="brandList" :brand-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Brand Area -->

        <Separator/>

        <!-- Start Service Area -->
        <!-- <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="What we can do for you"
                            title="Services provide for you."
                            description="There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceOne
                    service-style="service__style--1 bg-color-blackest radius mt--25"
                    icon-size="62"
                    text-align="center"
                />
            </div>
        </div> -->
        <!-- End Service Area -->
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="公司发展历程"
                            description="创新引领发展，科技成就收货。"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 mt--50">
                        <Timeline :timeline-data="timelineData" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="rwt-timeline-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->

        <Separator/>

        <AboutFour :image="require(`@/assets/images/logo/bg.png`)"/>

        <!-- Start Elements Area -->
        <div class="rwt-counterup-area pb--100">
            <div class="container mt_dec--30">
                <Counter
                    :counter-style="5"
                    text-align="center"
                    :counter-data="counterData"
                    column="col-lg-3 col-md-6 col-sm-6 col-12"
                />
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator/>

    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import AboutFour from '../components/elements/about/AboutFour'
    import ServiceOne from '../components/elements/service/ServiceOne'
    import Timeline from '../components/elements/timeline/Timeline'
    import Counter from '../components/elements/counterUp/Counter'
    import Brand from '../components/elements/brand/Brand'
    import Team from '../components/elements/team/Team'
    import Tab from '../components/elements/tab/Tab'

    export default {
        name: 'About',
        components: {Team, Brand, Counter, Timeline, ServiceOne, AboutFour, SectionTitle, Separator, Layout, Tab},
        data() {
            return {
                brandList: [
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-02.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-03.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-04.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-05.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-06.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-07.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-08.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/brand-01.png`)
                    },

                ],
                timelineData: [
                    {
                        id: '1',
                        title: '2020年12月4日',
                        description: '贵州通济智医科科技有限公司成立',
                    },
                    {
                        id: '2',
                        title: '2021年4月',
                        description: '第一台智慧无人药房进入市场',
                    },
                    {
                        id: '3',
                        title: '2021年8月',
                        description: '全国首家无感染智慧无人药房上市',
                    },
                    {
                        id: '4',
                        title: '2022年8月',
                        description: '公司被评为贵州省科技型中小企业AAA级信用企业',
                    },
                    {
                        id: '5',
                        title: '2022年12月',
                        description: '公司已自主完成10个专利11个软著知识产权的申报',
                    },
                    {
                        id: '6',
                        title: '2023年1月',
                        description: '获得鑫资本集团-香港永安达集团Pre-A轮数千万融资',
                    },
                    {
                        id: '7',
                        title: '2023年3月',
                        description: '广州通济智医分公司成立，完成ISO9001质量管理体系认证',
                    },
                    {
                        id: '8',
                        title: '2023年-2025年',
                        description: '计划完成线上诊疗+线下购买一体化物联网医疗+新零售平台建成',
                    },
                ],
                counterData: [
                    {
                        number: 30,
                        title: '三甲',
                    },
                    {
                        number: 11,
                        title: '三级其他',
                    },
                    {
                        number: 74,
                        title: '二甲',
                    },
                    {
                        number: 22,
                        title: '二级其他',
                    }
                ],
                tabData: {
                    tabImage: 'bg.png',
                    tabContent: [
                        {
                            id: 1,
                            menu: '管理系统',
                            content: `<p>公司着力于研发医疗产品零售终端自助设备和智慧医药SaaS软件平台技术，利用物联网技术打通“人与社区/乡镇-医院/医生-药店/药房”医药产品流通信息化管理系统。</p>`,
                        },
                        {
                            id: 2,
                            menu: '物联网',
                            content: `<p>创新的医疗产品零售新模式不仅为医药零售企业、医疗机构、配送企业等提供互联网+药品流通物联网整体解决方案。</p>`,
                        },
                        {
                            id: 3,
                            menu: '惠民便民',
                            content: `<p>为医保控费、药品监管、基层三医联动、疫情防控及社区康养慢病管理提供切实可行的项目解决方案，提供惠民便民的健康消费生活模式。</p>`,
                        }
                    ]
                },
            }
        }
    }
</script>