<template>
    <form class="contact-form-1">
        <div class="form-group">
            <input
                type="text"
                v-model="form.name"
                placeholder="您的姓名"
                required
            />
        </div>

        <div class="form-group">
            <input
                type="email"
                v-model="form.email"
                placeholder="您的邮箱"
                required
            />
        </div>

        <div class="form-group">
            <input
                type="text"
                v-model="form.phone"
                placeholder="您的电话"
                required
            />
        </div>


        <div class="form-group">
            <input
                type="text"
                v-model="form.subject"
                placeholder="项目意向"
                required
            />
        </div>

        <div class="form-group">
                <textarea
                    placeholder="详细描述"
                    v-model="form.message"
                    required>
                </textarea>
        </div>

        <div class="form-group">
            <button class="btn-default" type="button" v-on:click="sendEmail()">发 送</button>
        </div>

        <div class="form-group" style="color:#f00;" v-html="showResult ? result : null"/>
    </form>
</template>

<script>
    import axios from 'axios'
    export default {
        name: 'ContactForm',
        data() {
            return {
                showResult: false,
                result: `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`,
                form: {
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                }
            }
        },
        methods: {
            sendEmail() {
                axios.post(`https://inside.tongjizhiyi.com/index.php?s=/api/passport/get_email`,this.form).then(
					response => {
                        if(response.data.status==500){
                            this.showResult = true
                            this.result = response.data.message
                        }else{
                            this.showResult = false
                            this.form = {
                                name: '',
                                email: '',
                                phone: '',
                                subject: '',
                                message: '',
                            }
                            alert('感谢你的来信')
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
                // this.showResult = true
            }

        }
    }
</script>