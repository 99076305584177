<template>
    <Layout header-class="header-left-align" nav-alignment="left" :footer-style="2" buy-button-class="btn-default btn-small">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-3 height-850">
            <div class="container">
                <div class="row row--30 align-items-center">
                    <div class="order-2 order-lg-1 col-lg-7 mt_md--50 mt_sm--50 mt_lg--30">
                        <div class="inner text-left">
                            <h1 class="title theme-gradient" data-aos="fade-up" data-aos-delay="100">通济智医
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="130" style="text-indent: 2em;">
                                贵州通济智医科技有限公司成立于是2020年12月4日，是一家以物联网自动化技术+大数据为基础，以患者为核心-医疗服务为抓手，提供全场景智慧医药终端生态链的综合服务商。公司着力于研发医疗产品零售终端自助设备和智慧医药SaaS软件平台技术，利用物联网技术打通“人与社区/乡镇-医院/医生-药店/药房”医药产品流通信息化管理系统。
                            </p>
                            <div class="button-group mt--30 mt_sm--20" data-aos="fade-up" data-aos-delay="160">
                                <a class="btn-default btn-medium btn-icon"
                                   href="#">
                                    Purchase Now
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="order-1 order-lg-2 col-lg-5" data-aos="fade-up" data-aos-delay="100">
                        <div class="thumbnail">
                            <img src="../../assets/images/about/about-2.png" alt="Banner Images"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator/>

        <!-- Start Elements Area -->
        <div class="rwt-tab-area rn-section-gap">
            <div class="container">
                <div class="row mb--40">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Companies About"
                            title="致力于打造全国领先的医疗销售平台！"
                            description="创新的医疗产品零售新模式不仅为医药零售企业、医疗机构、配送企业等提供互联网+药品流通物联网整体解决方案，同时还为医保控费、药品监管、基层三医联动、疫情防控及社区康养慢病管理提供切实可行的项目解决方案，提供惠民便民的健康消费生活模式。"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Tab :tab-data="tabData"/>
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator/>

        <!-- Start Timeline Area -->
        <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="公司发展历程"
                            description="创新引领发展，科技成就收货。"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 mt--50">
                        <Timeline :timeline-data="timelineData" :timeline-style="4"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Timeline Area -->

        <Separator/>

        <!-- Start About Area -->
        <div class="rwt-about-area rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-5">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img class="w-100" src="../../assets/images/about/about-1.png" alt="About Images">
                        </div>
                    </div>
                    <div class="col-lg-7 mt_md--40 mt_sm--40">
                        <div class="content">
                            <SectionTitle
                                subtitle="WORKFLOW INTEGRATION"
                                title="Companies Platform."
                                data-aos="fade-up"
                            />
                            <accordion
                                id="accordionExampleOne"
                                data-aos="fade-up"
                                data-aos-delay="60"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End About Area -->

        <Separator/>

        <!-- Start Pricing Area -->
        <div class="rwt-pricing-area rn-section-gap">
            <div class="container">
                <div class="row mb--35">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Our Pricing Plan"
                            title="Business Pricing Plan"
                            description="We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Pricing :pricing-data="pricingData" :pricing-style="3"/>
            </div>
        </div>
        <!-- End Pricing Area -->

        <Separator/>

        <!-- Start Blog Area -->
        <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Latest News"
                            title="Our Latest News"
                            description="We provide company and finance service for <br /> startups and company business"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100 + index"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index < 3">
                        <BlogPost :blog="blog"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Blog Area -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import Separator from '../../components/elements/separator/Separator'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import Tab from '../../components/elements/tab/Tab'
    import Timeline from '../../components/elements/timeline/Timeline'
    import Accordion from '../../components/elements/accordion/Accordion'
    import Pricing from '../../components/elements/pricing/Pricing'
    import BlogPostMixin from '../../mixins/BlogPostMixin'
    import BlogPost from '../../components/blog/BlogPost'

    export default {
        name: 'Business',
        components: {BlogPost, Pricing, Accordion, Timeline, Tab, SectionTitle, Separator, Icon, Layout},
        mixins: [BlogPostMixin],
        data() {
            return {
                tabData: {
                    tabImage: 'tabs-01.jpg',
                    tabContent: [
                        {
                            id: 1,
                            menu: 'strategy',
                            content: `<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p><p>Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>`,
                        },
                        {
                            id: 2,
                            menu: 'reporting',
                            content: `<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p><p>Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>`,
                        },
                        {
                            id: 3,
                            menu: 'development',
                            content: `<p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p><p>Quibusdam ipsa, deleniti soluta minima minus asperiores doloribus enim vitae obcaecati fuga assumenda laudantium nemo odio provident nulla exercitationem tempore corrupti! Nemo.</p>`,
                        }
                    ]
                },
                timelineData: [
                    {
                        id: '1',
                        title: '2020年12月4日',
                        description: '贵州通济智医科科技有限公司成立',
                    },
                    {
                        id: '2',
                        title: '2021年4月',
                        description: '第一台智慧无人药房进入市场',
                    },
                    {
                        id: '3',
                        title: '2021年8月',
                        description: '全国首家无感染智慧无人药房上市',
                    },
                    {
                        id: '4',
                        title: '2022年8月',
                        description: '公司被评为贵州省科技型中小企业AAA级信用企业',
                    },
                    {
                        id: '5',
                        title: '2022年12月',
                        description: '公司已自主完成10个专利11个软著知识产权的申报',
                    },
                    {
                        id: '6',
                        title: '2023年1月',
                        description: '获得鑫资本集团-香港永安达集团Pre-A轮数千万融资',
                    },
                    {
                        id: '7',
                        title: '2023年3月',
                        description: '广州通济智医分公司成立，完成ISO9001质量管理体系认证',
                    },
                    {
                        id: '8',
                        title: '2023年-2025年',
                        description: '计划完成线上诊疗+线下购买一体化物联网医疗+新零售平台建成',
                    },
                ],
                pricingData: [
                    {
                        title: 'Free',
                        currency: '$',
                        price: '29',
                        subtitle: 'USD Per Month',
                        features: [
                            {title: '5 PPC Campaigns'},
                            {title: 'Digital Marketing'},
                            {title: 'Marketing Agency'},
                            {title: 'Seo Friendly'},
                            {title: 'UI/UX designs'},
                        ],
                        btn: {
                            text: 'Purchase Now',
                            link: '#'
                        }
                    },
                    {
                        title: 'Business',
                        currency: '$',
                        price: '50',
                        subtitle: 'USD Per Month',
                        features: [
                            {title: '5 PPC Campaigns'},
                            {title: 'Keep 100% Royalties'},
                            {title: 'App Development'},
                            {title: 'Seo Friendly'},
                            {title: 'UI/UX designs'},
                        ],
                        btn: {
                            text: 'Purchase Now',
                            link: '#'
                        },
                        isActive: true
                    }
                ],
            }
        }
    }
</script>