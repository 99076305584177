<template>
    <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
        <div class="accordion" :id="id">
            <div class="accordion-item card" v-for="(content, index) in accordionContent">
                <h2 class="accordion-header card-header" :id="`heading${content.id}`">
                    <button class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#collapse${content.id}`"
                            :aria-expanded="index === activeCollapse ? 'true' : 'false'"
                            :aria-controls="`collapse${content.id}`"
                            @click="activeCollapse = index">
                        {{ content.title }}
                        <i class="collapse-icon"/>
                    </button>
                </h2>
                <div :id="`collapse${content.id}`"
                     class="accordion-collapse collapse"
                     :class="{'show': index === 0}"
                     :aria-labelledby="`heading${content.id}`"
                     :data-bs-parent="`#${id}`">
                     <div class="accordion-body card-body" v-html="content.description">
                  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Accordion',
        props: {
            id: {
                type: String,
                default: 'accordionExample'
            },
            accordionStyleClass: {
                type: String
            },
            accordionContent: {
                type: Array,
                default: function () {
                    return [
                        {
                            id: 'One',
                            title: '中央统战部领导莅临广州市天河区港澳青年之家总部参观并考察通济智医广州分公司！',
                            description: '2023年5月6日，中央统战部副部长林锐、广东省委常委统战部部长王瑞军、广州市委常委统战部部长王世彤等一行领导，莅临广州市天河区港澳青年之家总部创业孵化基地参观考察交流学习。期间，创业孵化基地创始人陈贤翰，向领导们介绍“通济智医智慧药房”项目在粤港澳大湾区的发展情况，成绩得到领导们的大力认可，并表示：未来将会在粤港澳大湾区全力给予支持。'
                        },
                        {
                            id: 'Two',
                            title: '黑龙江·明水县人民医院引入通济智医.智慧无人药房！',
                            description: '明水县人民医院始建于1936年，是一所集医疗、教学、科研、急救、预防保健为一体的国家二级甲等综合医院；是北京阜外医院、中国武警总医院、天津市肿瘤医院、哈医大一院等8家三甲医院的协作医院；是全县急诊急救中心、健康扶贫中心、胸痛中心、卒中中心、康复中心、远程会诊中心、孕产妇急重症救治中心、新生儿救治中心、明水县法医鉴定所。2019年晋升为省级文明单位标兵。'
                        },
                        {
                            id: 'Three',
                            title: '贵州省.福泉市第一人民医院引入通济智医.智慧无人药房！',
                            description: '福泉市第一人民医院位于贵州省福泉市新华北路14号，创建于1950年，是全市唯一一所三级综合医院，医院连续多年获黔南州县（市）级医院医疗质量综合评比第一名； 多次荣获“省级文明单位”和州、市级文明单位；2018年成为黔南州通过国家“基层胸痛中心”创建的首家县级医院；2020年3月取得贵州省助理全科医生培训基地资质；2021年11月成为黔南州首家晋升“三级综合医院”的县（市）级医院; 2021年度全省公立医院高质量发展考核名列全省87家县级综合医院第一名。医院的综合实力已跻身全省先进县（市）级医院行列。'
                        },
                    ]
                }
            }
        },
        data() {
            return {
                activeCollapse: 0
            }
        }
    }
</script>
<style scoped>
.rn-accordion-style .card .card-header button{
    font-size: 16px !important;
}
</style>
