<template>
    <div class="row row--15 service-wrapper">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon">
                    <div class="line"/>
                    {{ service.icon }}
                </div>
                <div class="content">
                    <p class="title title_new">
                        {{ service.title }}
                        <!-- <router-link to="/service-details" v-html="service.title"/> -->
                    </p>
                    <p class="description b1 color-gray mb--0" v-html="service.description"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServiceFour',
        props: {
            // serviceList: {},
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        data() {
            return {
                serviceList: [
                    {
                        icon: '1',
                        title: '顺应时代背景',
                        description: '创新患者购买方式，提高医院便民服务水平'
                    },
                    {
                        icon: '2',
                        title: '响应国家政策',
                        description: '应对医改政策，处方外流趋势，降低耗占比'
                    },
                    {
                        icon: '3',
                        title: '一站式服务',
                        description: '与医院传统就医模式形成互补，实现患者“一站式”服务'
                    },
                    {
                        icon: '4',
                        title: '便民惠民无忧',
                        description: '365天24h无人售卖，提高医院智慧医疗服务形象，改善就医环境'
                    },
                ]
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        }
    }
</script>