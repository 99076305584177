<template>
    <Layout :footer-style="2">
        <!-- Start Slider Area -->
        
        <div ref="lunbo" class="demo-slider-area slider-area bg-transparent slider-style-1 lunbo" id="lunbo">
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide>
                    <div class="reason-item">
                        <!-- <div class="swiper-tit">致力于打造全国领先的医疗销售平台！为上万个医疗生产企业在医院再建一个家园！</div> -->
                        <!-- <div class="desc">致力于打造全国领先的医疗销售平台！为上万个医疗生产企业在医院再建一个家园！</div> -->
                        <!-- <img src="../assets/img/A025.png" alt="" class="deck-img" width="100%"> -->
                        <img src="../assets/img/A03.png" alt="" class="deck-img" width="100%">
                    </div>
                    <!-- <img src="https://inside.tongjizhiyi.com/uploads/10001/20230518/7842dbfc21a5e85871051e2d2acbbe6a.jpg" width="100%"/> -->
                </swiper-slide>
                <swiper-slide>
                    <div class="reason-item">
                        <!-- <div class="swiper-tit">一个万物互联、人机智联的数字时代正在开启</div> -->
                        <!-- <div class="desc">从新一轮科技革命与产业变革的大趋势看：第四次工业革命以数字化、智能化、网络化为核心</div> -->
                        <img src="../assets/img/A02.png" alt="" class="deck-img" width="100%">
                    </div>
                    <!-- <img src="https://inside.tongjizhiyi.com/uploads/10001/20230517/0da2a37b8252a827f77b2998646e8723.png" width="100%"/> -->
                </swiper-slide>
                <swiper-slide>
                    <div class="reason-item">
                        <!-- <div class="swiper-tit">智慧医疗作为新基建未来十大场景之一，未来可期！</div> -->
                        <!-- <div class="desc">线上线下互联互通，全渠道全场景实体化的运营体系迎来发展机遇</div> -->
                        <img src="../assets/img/A01.png" alt="" class="deck-img" width="100%">
                    </div>
                    <!-- <img src="https://inside.tongjizhiyi.com/uploads/10001/20230518/5990df60b359ad5a71aa3a4b8944be42.jpg" width="100%"/> -->
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
            <!-- <div class="container">

                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <div class="react-image mb--20" data-aos="fade-up">
                                <img src="../assets/images/logo/logo.png" alt="Doob Vue"/>
                            </div>
                            <h1 class="title display-two" data-aos="fade-up" data-aos-delay="100">
                                <span v-if="title.title1">{{ title.title1 }}</span> <br/>
                                <span v-if="title.title2" class="theme-gradient">{{ title.title2 }}</span> &
                                <span v-if="title.title3" class="theme-gradient">{{ title.title3 }}</span>
                                <br/>
                                <span v-if="title.title4">{{ title.title4 }}</span>
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="150">
                                We help our clients succeed by creating brand identities, digital
                                experiences, and print materials.
                            </p>
                            <div class="button-group" data-aos="fade-up" data-aos-delay="200">
                                <a class="btn-default btn-large round btn-icon" href="#demo">View Demo
                                    <Icon name="arrow-down"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- End Slider Area -->

        <Separator/>
        <div class="rn-demo-area rn-section-gap">
            <div class="container mt_dec--30">
                <Counter
                    :counter-style="5"
                    text-align="center"
                    :counter-data="counterData"
                    column="col-lg-2 col-md-6 col-sm-6 col-12"
                />
            </div>
        </div>

        <Separator/>
        <div class="rn-service-area">
            <div class="container" style="background-color: #FAFAFA;">
                <div class="row no-gutters radius-10 align-items-center">
                    <div class="col-lg-12 col-xl-7 col-12">
                        <div id="china_map_box">
                            <div id="chart_map" style="width:100%;min-height:600px;padding:-100px;"></div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-xl-5 col-12">
                        <div class="split-inner">
                            <p class="title title_new">通济智医·智慧无人药房</p>
                            <p class="description title_new">为上万个医疗生产企业在医院再建一个家园！</p>
                            <p class="description mb--30"></p>
                            <ul class="split-list">
                                <li>将医疗产品送至科室门口</li>
                                <li>让院外销售产品实现体内循环</li>
                                <li>24h健康便民惠民无忧</li>
                                <li>提高患者就医满意度</li>
                                <!-- <li v-for="listItem in splitData.list">{{ listItem }}</li> -->
                            </ul>
                            <!-- <div class="view-more-button mt--35">
                                <Button :title="splitData.btn.text" :url="splitData.btn.link"/>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Start Our Demo -->
        <!-- <div class="rn-demo-area rn-section-gap" id="demo">
            <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="All Demo Here"
                            title="Doob All Demo."
                            description="We create a business and consulting Vue.js template with 50+ elements features. <br /> 19+ demo pages, faster loading and well documentated code."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12 mt--40"
                         v-for="(page, index) in demoPages"
                         :key="index"
                         data-aos="fade-up"
                         :data-aos-delay="100 + index">
                        <div class="single-demo">
                            <router-link :to="page.url">
                                <div class="thumbnail">
                                    <img class="image-dark" :src="page.image" alt="Corporate images"/>
                                    <img class="image-light" :src="page.imageLight" alt="Corporate images"/>
                                    <span v-if="page.badge" class="label-badge">{{ page.badge }}</span>
                                </div>
                            </router-link>
                            <h4 class="title">
                                <router-link :to="page.url">{{ page.title }}</router-link>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Our Demo -->


        <!-- Start Inner Pages -->
        <!-- <div class="rn-inner-pages rn-section-gap">
            <div class="wrapper plr--150 plr_lg--30 plr_md--30 plr_sm--30">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="All Others Pages"
                            title="All Others Pages."
                            description="Have a nice inner pages include in doob template."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-12 mt--40"
                         v-for="(page, index) in innerPages"
                         :key="index"
                         data-aos="fade-up"
                         :data-aos-delay="100 + index">
                        <div class="single-demo">
                            <router-link :to="page.url">
                                <div class="thumbnail">
                                    <img class="image-dark" :src="page.image" alt="Corporate images"/>
                                    <img class="image-light" :src="page.imageLight" alt="Corporate images"/>
                                </div>
                            </router-link>
                            <h4 class="title">
                                <router-link :to="page.url">{{ page.title }}</router-link>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Inner Pages -->
        <Separator/>
        <div class="rwt-portfolio-area rn-section-gap">
            <div class="container mt--20">
                <Portfolio :portfolio-data="portfolioItems" data-aos="fade-up"/>
            </div>
        </div>
    </Layout>
</template>
<script type="text/javascript" src="../assets/js/js/china.js"></script> 
<script>
    import Layout from '../components/common/Layout'
    import Icon from '../components/icon/Icon'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Portfolio from '../components/elements/about/Menus'
    import Counter from '../components/elements/counterUp/Counter'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    import 'swiper/dist/css/swiper.css'
    import axios from 'axios'
    // import echarts from 'echarts'
    import * as echarts from 'echarts'
    import '../assets/js/js/china'
    

    export default {
        name: 'Demo',
        
        components: {Icon, Layout, Separator, SectionTitle,Counter,swiper,swiperSlide,Portfolio},
        data() {
            return {
                counterData: [],
                map: '',
                lunbo_width:100,
                lunbo_height:100,
                title: [],
                slides: [
                    {
                        src: 'https://inside.tongjizhiyi.com/uploads/10001/20230418/7edaa396833957b7bc8e19ef454338ca.png',
                    },
                    {
                        src: 'https://inside.tongjizhiyi.com/uploads/10001/20230418/7edaa396833957b7bc8e19ef454338ca.png'
                    },
                    {
                        src: 'https://inside.tongjizhiyi.com/uploads/10001/20230418/7edaa396833957b7bc8e19ef454338ca.png'
                    }
                ],
                swiperOptions: {
                    pagination: {
                        el: '.swiper-pagination',    //与slot="pagination"处 class 一致
                        clickable: true,            //轮播按钮支持点击
                        renderBullet: function (index, className) {
                         return '<div style="color:#002060;background-color:#fff;width:26px;height:26px;text-align:center;" class="' + className + '">' + (index + 1) + '</div>';
                        },
                        
                    },
                    //自动播放
					autoplay: {
						delay: 400000,
						disableOnInteraction: false
					},
					//循环
					loop:true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                }
            }
        },
        created() {
            // this.setEchartOption();
            this.init()
            this.get_map()
        },
        methods: {
            init() {
                var width = window.innerWidth
                this.lunbo_width = width - (width*0.15)
                axios.get(`https://inside.tongjizhiyi.com/index.php?s=/api/passport/get_index`).then(
					response => {
                        if(response.data.status==200){
                            this.counterData = response.data.data.counterData2
                            this.map = response.data.data.map
                            this.title = response.data.data.title
                        }else{
                            this.result = response.data.message
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
            },
            lunbo_widths(){
                var width = window.innerWidth
                return width - (width*0.15);
                this.lunbo_width = width - (width*0.15)
            },
            get_map(){
                axios.post('https://inside.tongjizhiyi.com/index.php?s=/api/passport/province',{
                }).then(res=>{
                    this.list = res.data.data.list;
                    setTimeout(() => {
                        this.set_map();
                    }, 1000);
                }).catch(err=>{
                
                })
            },
            // //初始化中国地图
            // initEchartMap() {
            //     let mapDiv = document.getElementById('china_map');
            //     let myChart = echarts.init(mapDiv);
            //     myChart.setOption(this.options);
            // },
            // //修改echart配制
            // setEchartOption(){
            //     this.options.series[0]['data'] = this.dataList;
            // },
            set_map(){
                    var list = this.list
                    // 基于准备好的dom，初始化echarts实例
                    var myChart = echarts.init(document.getElementById('chart_map'));
                    console.log(myChart)
                    var mapName = 'china'
                    var data = []
                    var toolTipData = list.data;

                    /*获取地图数据*/
                    myChart.showLoading();
                    var mapFeatures = echarts.getMap(mapName).geoJson.features;
                    myChart.hideLoading();
                    var geoCoordMap = list.city;

                    var GZData = list.size;
                    var convertData = function (data) {
                        var res = [];
                        for (var i = 0; i < data.length; i++) {
                            var dataItem = data[i];
                            var fromCoord = geoCoordMap[dataItem[0].name];
                            var toCoord = geoCoordMap[dataItem[1].name];
                            if (fromCoord && toCoord) {
                                res.push({
                                    fromName: dataItem[0].name,
                                    toName: dataItem[1].name,
                                    coords: [fromCoord, toCoord]
                                });
                            }
                        }
                        return res;
                    };
                    let iconRQ = "image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAUCAYAAABiS3YzAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTM4IDc5LjE1OTgyNCwgMjAxNi8wOS8xNC0wMTowOTowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTcgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjNCRkRBMEJBQzgwRjExRUFBNUI0RTMyMThEN0UxMzFEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjNCRkRBMEJCQzgwRjExRUFBNUI0RTMyMThEN0UxMzFEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6M0JGREEwQjhDODBGMTFFQUE1QjRFMzIxOEQ3RTEzMUQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6M0JGREEwQjlDODBGMTFFQUE1QjRFMzIxOEQ3RTEzMUQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz5mT42iAAABQ0lEQVR42mL8LabOQCQIBOL1xChkItJAkLp+IBajpqFWQCwPxJ7UNDQCSgdQy1BmIA6Bsl2AmJMahjoAsTiUzQPETtQwNAKN709IAwvUayZQ/hcg/o0k/x6Ig9D0+ABxKJT9HYh/oMm/BBm6GYitgTgfiBmJcLkkEK/CIXcGiGNB3v8JxIVQF31gIA/8AeIWaNK7gRymG4BYH4hPkGjgXSC2A+JaWNChR9QjqIJeIP5PhIGzgdgAiI8Tin2QbSVAvIOAgROBOA0auUQlKV4gtidgqBGp6RSUFrmIKA/ESDEUPcGfBOIUIH6Lln29iTVUCIjdkJJKExDbAPFcqJdPEMpd2AwF5TBWaFKxBeJ6qOHIqaMbmjrcsBUw2AwNh7rKAEeaBaWOMiD2BeJvQOxOyFBuaFJJwZZU0MBWaHCIo0sABBgAetA4Jx5t/ToAAAAASUVORK5CYII="
                    var color = ['#fff'];//ffeb40,D6161A,002060
                    var series = [];
                    [
                        ['贵阳', GZData]
                    ].forEach(function (item, i) {
                        series.push({
                            name: item[0],
                            type: 'lines',
                            zlevel: 2,
                            symbol: ['arrow'],
                            symbolSize: 15,
                            effect: {
                                show: true,
                                period: 1.5,
                                trailLength: 0.1,
                                color: color[i],
                                // symbol: planePath,
                                symbolSize: 6,
                                trailLength: 0.5,
                                symbol: 'arrow',
                                symbolSize: 10
                            },
                            lineStyle: {
                                normal: {
                                    color: color[i],
                                    width: 1.5,
                                    opacity: 0.8,
                                    curveness: 0.2
                                }
                            },
                            data: convertData(item[1])
                        }, {
                            name: item[0],
                            type: 'effectScatter',
                            coordinateSystem: 'geo',
                            zlevel: 2,
                            rippleEffect: {
                                period: 4,
                                scale: 2.5,
                                brushType: 'stroke'
                            },
                            roam: false,
                            label: {
                                normal: {
                                    show: true,
                                    fontSize: 14,
                                    position: 'right',
                                    formatter: '{b}',
                                    textStyle:{color:"#fff"}
                                }
                            },
                            symbolSize: function (val) {
                                return val[2] / 8;
                            },
                            itemStyle: {
                                normal: {
                                    color: color[i]
                                }
                            },
                            data: item[1].map(function (dataItem) {
                                return {
                                    name: dataItem[1].name,
                                    value: geoCoordMap[dataItem[1].name].concat([dataItem[1].value])
                                };
                            })
                        },{ //首都星图标
                            name: '首都',
                            type: 'scatter',
                            coordinateSystem: 'geo',
                            data: [{
                                name: '首都',
                                value: [116.74, 40.45, 100],

                            }, ],
                            symbol: iconRQ,
                            symbolSize: 15,
                            label: {
                                normal: {
                                    show: false,

                                },
                                emphasis: {
                                    show: false
                                }
                            },

                        },);
                    });

                    var regions_list = []
                    list.data.forEach(elements => {
                        regions_list.push({
                            name: elements.name,
                            itemStyle: {
                                normal: {
                                    areaColor: elements.color
                                }
                            }
                        })
                    });
                    console.log(regions_list)
                    var option = {
                        tooltip: {
                            trigger: 'item',
                            formatter: function(params) {
                                console.log(typeof(params.value))
                                if (typeof(params.value) == "undefined") {
                                    var toolTiphtml = ''
                                    for(var i = 0;i<toolTipData.length;i++){
                                        if(params.name==toolTipData[i].name){
                                            toolTiphtml += '<p style="line-height:60px;font-size:16px;color:#111;"><b>'+toolTipData[i].name+'</b><br>'
                                            for(var j = 0;j<toolTipData[i].value.length;j++){
                                                toolTiphtml+=toolTipData[i].value[j].name+':<span style="color:#f00;font-size:20px;">'+toolTipData[i].value[j].value+"</span><br>"
                                            }
                                            toolTiphtml = toolTiphtml + '</p>'
                                        }
                                    }
                                    // console.log(toolTiphtml)
                                    // console.log(convertData(data))
                                    return toolTiphtml;
                                } else {
                                    var toolTiphtml = ''
                                    for(var i = 0;i<toolTipData.length;i++){
                                        if(params.name==toolTipData[i].name){
                                            toolTiphtml += '<span style="lineheight:40px;font-size:16px;color:#111;">'+toolTipData[i].name+':<br>'
                                            for(var j = 0;j<toolTipData[i].value.length;j++){
                                                toolTiphtml+=toolTipData[i].value[j].name+':<span style="color:#f00;font-size:20px;">'+toolTipData[i].value[j].value+"</span><br>"
                                            }
                                            toolTiphtml = toolTiphtml + '</span>'
                                        }
                                    }
                                    // console.log(convertData(data))
                                    return toolTiphtml;
                                }
                            }
                            
                        },
                        geo: {
                            map: 'china',
                            label: {
                                // emphasis: {
                                //     show: false
                                // }
                            },
                            roam: false,
                            itemStyle: {
                                normal: {
                                    borderColor: '#fff',
                                    borderWidth: 1,
                                    areaColor: {
                                        type: 'radial',
                                        x: 0.5,
                                        y: 0.5,
                                        r: 0.8,
                                        colorStops: [{
                                            offset: 0,
                                            color: '#0D4AA2' // 0% 处的颜色
                                        }, {
                                            offset: 1,
                                            color: '#0D4AA2' // 100% 处的颜色
                                        }],
                                        // globalCoord: false // 缺省为 false
                                    },
                                    // normal: {
                                    //     areaColor: '#031525',
                                    //     borderColor: '#3B5077',
                                    // },
                                    // shadowColor: 'rgba(128, 217, 248, 1)',
                                    // shadowColor: 'rgba(255, 255, 255, 1)',
                                    // shadowOffsetX: -2,
                                    // shadowOffsetY: 2,
                                    // shadowBlur: 10
                                },
                                emphasis: {
                                    areaColor: '#2788E4',//#389BB7
                                    borderWidth: 0
                                }
                            },
                            regions: regions_list
                        },
                        series: series
                    };

                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(option);
                    window.addEventListener("resize", function () {
                        myChart.resize();
                    });
            },
        },
        mounted() {
            this.$nextTick(()=>{
                this.set_map();
            })
        
        }
}
</script>
<style lang="scss" scoped>
body{
    font-family: '等一个人';
}
.app-container{
  .swiper{
    width: 100%;
    
    
    &>>>.swiper-container {
      width: 100%;

      .swiper-slide {
        width: 100%;
        height: 0;
        padding-bottom: 28.1%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .swiper-pagination{
        span{
            width:30px !important;
            height:30px !important;
        }
        .swiper-pagination-bullet{
            width:30px !important;
            height:30px !important;
        }
      }
      
    }
  }
}
.reason-item{
    position: relative;
}
.reason-item .tit{
    position: absolute;
    width:100%;
    height:100%;
    display:flex;/*实现垂直居中*/
    align-items:center;/*实现水平居中*/
    justify-content:center;
    text-align:justify;
    // text-align: center;
    font-size: 35px;
    font-weight: 700;
    color: var(--color-zhutise1);
    // @media #{$large-mobile} {
    //     font-size: 15px;
    // }
}
// .reason-item .desc{
//     position: absolute;
//     width:100%;
//     top:50%;
//     height:50%;
//     text-align: center;
//     color:#00f;
// }
@keyframes wrapper-gradient {
    100% {
        transform: translateY(0);
    }
    0% {
        transform: translateY(100%);
    }
}
// .swiper-pagination-bullet{
//     width:30px !important;
//     height:30px !important;
// }
// .lunbo {
//     animation: wrapper-gradient 0.5s linear;
// }
</style>