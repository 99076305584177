<template>
    <router-view/>
</template>

<script>
    export default {
        name: 'APP',
        created() {
            if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
                window.location.replace("https://inside.tongjizhiyi.com/website");
            }
        },
    }
</script>
<style>

</style>