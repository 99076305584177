<template>
    <Layout>
        <!-- Start Contact Area  -->
        <div class="rwt-contact-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="联系我们"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-12">
                        <div class="rn-contact-address mt_dec--30">
                            <div class="row">
                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="headphones" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">联系电话</h4>
                                            <p><a :href="'tel:'+contact.contact_phone">{{ contact.contact_phone }}</a></p>
                                            <!-- <p><a href="tel:+86 070 9985647">+86 070 9985647</a></p> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="mail" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">联系邮箱</h4>
                                            <!-- <p><a href="mailto:18375210436@163.com">18375210436@163.com</a></p> -->
                                            <p><a :href="'mailto:'+contact.contact_eamil">{{ contact.contact_eamil }}</a></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-12">
                                    <div class="rn-address">
                                        <div class="icon">
                                            <Icon name="map-pin" :size="40"/>
                                        </div>
                                        <div class="inner">
                                            <h4 class="title">公司地址</h4>
                                            <p><a href="https://map.baidu.com/search/%E8%8C%85%E5%8F%B0%E5%95%86%E5%8A%A1%E4%B8%AD%E5%BF%83-c%E5%BA%A7/@11872611.505,3056920,19z?querytype=s&da_src=shareurl&wd=%E8%8C%85%E5%8F%B0%E5%95%86%E5%8A%A1%E4%B8%AD%E5%BF%83-C%E5%BA%A7&c=146&src=0&wd2=%E8%B4%B5%E9%98%B3%E5%B8%82%E8%A7%82%E5%B1%B1%E6%B9%96%E5%8C%BA&pn=0&sug=1&l=13&b=(11844985.71835486,3049211.3102438995;11906425.71835486,3079035.3102438995)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=2b8c087654b98663e5073877&device_ratio=1" target="_blank"> {{ contact.contact_addr }}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt--40 row--15">
                    <div class="col-lg-12">
                        <ContactForm/>
                    </div>
                    <!-- <div class="col-lg-5 mt_md--30 mt_sm--30">
                        <GoogleMap/>
                    </div> -->
                </div>
            </div>
        </div>
        <!-- End Contact Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Icon from "../components/icon/Icon";
    import GoogleMap from "../components/elements/contact/GoogleMap";
    import ContactForm from "../components/elements/contact/ContactForm";
    import axios from 'axios'

    export default {
        name: 'ContactPage',
        components: {ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout},
        data() {
            return {
                contact: [],
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                axios.get(`https://inside.tongjizhiyi.com/index.php?s=/api/passport/get_contact`).then(
					response => {
                        if(response.data.status==200){
                            this.contact = response.data.data.contact
                        }else{
                            this.result = response.data.message
                        }
					},
					error => {
						//请求后更新List的数据
					}
				)
            }

        }
    }
</script>