<template>
    <!-- <div v-if="sectionTitleStyle === 1" class="section-title" :class="checkTextAlign">
        <h4 class="subtitle">
            <span class="theme-gradient" v-html="subtitle"/>
        </h4>
        <h2 class="title w-600" v-html="title"/>
        <p v-if="description" class="description b1" v-html="description"/>
    </div>

    <div v-else-if="sectionTitleStyle === 2" :class="`section-title-2 ${checkTextAlign}`">
        <h2 class="title">{{ title }}</h2>
    </div> -->
    <div class="row no-gutters radius-10 align-items-center" :class="checkTextAlign">
        <div class="col-lg-12 col-xl-4 col-12">
            <div id="china_map_box" class="gradient-wrapper">
                <img src="@/assets/img/robot.png" alt="Split Image"/>
            </div>
        </div>
        <div class="col-lg-12 col-xl-8 col-12 robot_text">
            <div class="split-inner" style="text-align:center;">
                <p class="title title_new_big">通济智医·智慧无人药房平台</p>
                <p class="description">以消费医疗为基础
                <br/>面对医疗机构-患者-企业-居家养老-社区-基层等提供便民的医疗服务平台
                <br/>通济智医
                <br/>将全国健康医疗资源与区域内医疗资源相互连接
                <br/>建设智慧无人药房平台
                <br/>以地区核心医院为中心
                <br/>建设覆盖全域的医疗服务共同体
                <br/>致力于打造全国领先的医疗销售平台!</p>
                <!-- <ul class="split-list">
                    <li>顺应时代大背景，可创新患者购买方式，提高医院便民服务水平；</li>
                    <li>顺应国家医改政策，可应对处方外流趋势，可降低医院耗占比；</li>
                    <li>与医院现有产品形成互补，实现患者“一站式”服务，在医院院内实现全部产品的购买；</li>
                    <li>全天候开放，满足 24小时患者便民产品购买需求，提高医院智慧医疗服务形象，改善就医环境。</li>
                </ul> -->
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SectionTitle2',
        props: {
            subtitle: {
                type: String,
                default: null
            },
            title: {
                type: String,
                default: null
            },
            description: {
                type: String,
                default: null
            },
            textAlign: {
                type: String,
                default: 'left'
            },
            sectionTitleStyle: {
                type: Number,
                default: 1
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            }
        },
    }
</script>
<style scoped>
/* @keyframes wrapper-gradient {
        100% {
            transform: translateY(0);
        }
        0% {
            transform: translateY(100%);
        }
    }
    @keyframes img-gradient {
      100% {
        transform: translateX(0);
      }
      0% {
        transform: translateX(-100%);
      }
    } */
    /* .gradient-wrapper>img {
      animation: wrapper-gradient 0.8s linear;
    }
    .robot_text {
      animation: wrapper-gradient 0.8s linear;
    } */
    /* .bg_image {
        animation: wrapper-gradient 0.5s linear;
    } */
</style>