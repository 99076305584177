<template>
    <div v-if="tabStyle === 1 || tabStyle === 2"
         class="row row row--30">
        <div class="col-lg-5">
            <img class="radius-small"
                 src="@/assets/img/gsjs.png"
                 alt="Tab Image">
        </div>
        <div class="col-lg-7 mt_md--40 mt_sm--40 order-2 order-lg-1">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="公司详细介绍"
                        data-aos="fade-up"
                    />
                </div>
            </div>
            <div>
                贵州通济智医科技有限公司（简称”通济智医”），公司成立于2020年12月4日，是一家以物联网自动化技术+大数据为基础，以患者为核心-医疗服务为抓手，提供全场景智慧医药终端生态链的综合服务商；
            </div>
            <div>
                公司着力于研发医疗产品零售终端自助设备和智慧医药SaaS（Software as a Service）软件平台技术，利用物联网技术打通“人与社区/乡镇-医院/医生-药店/药房”医药产品流通信息化管理系统。创新的医疗产品零售新模式不仅为医药零售企业、医疗机构、配送企业等提供互联网+药品流通物联网整体解决方案，同时还为医保控费、药品监管、基层三医联动、疫情防控及社区康养慢病管理提供切实可行的项目解决方案，提供惠民便民的健康消费生活模式。
            </div>
        </div>
    </div>

    <div v-else-if="tabStyle === 3" class="rn-default-tab style-three">
        <ul class="nav nav-tabs tab-button" role="tablist">
            <li class="nav-item tabs__tab"
                role="presentation"
                v-for="(tabMenu, index) in tabData"
                :key="index">
                <button class="nav-link text-capitalize"
                        :class="{'active': index === 0}"
                        :id="`${tabMenu.id}-tab`"
                        data-bs-toggle="tab"
                        :data-bs-target="`#${tabMenu.id}`"
                        type="button"
                        role="tab"
                        :aria-controls="tabMenu.id"
                        :aria-selected="index === activeTab ? 'true' : 'false'"
                        @click="activeTab = index">
                    {{ tabMenu.text }}
                </button>
            </li>
        </ul>

        <div class="rn-tab-content tab-content">
            <div class="tab-pane fade"
                 :class="{'show active': index === 0}"
                 :id="content.id"
                 role="tabpanel"
                 :aria-labelledby="`${content.id}-tab`"
                 v-for="(content, index) in tabData"
                 :key="index">
                <div class="inner">
                    <div class="row align-items-center row--30">
                        <div v-if="index !== tabData.length - 1" class="col-lg-4">
                            <div class="section-title">
                                <h4 class="title">How can we help you?</h4>
                                <p>
                                    There are many variations variats of passages of Lorem Ipsum
                                    available many variations variats of passages of Lorem Ipsum
                                    available.
                                </p>
                                <div class="read-more">
                                    <a class="btn-default btn-small" href="#">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div :class="index !== tabData.length - 1 ? 'col-lg-8 mt_md--30 mt_sm--30' : 'col-lg-12 mt_md--30 mt_sm--30'">
                            <div class="row row--15 mt_dec--30 service-wrapper">
                                <div :class="content.dataList.length === 2 ? 'col-lg-6 col-md-6 col-sm-6 col-12' : 'col-lg-4 col-md-6 col-sm-6 col-12'"
                                     v-for="(service, index) in content.dataList"
                                     :key="`service-${index}`">
                                    <div class="card-box card-style-1 text-left">
                                        <div class="inner">
                                            <div class="image">
                                                <router-link to="#">
                                                    <img :src="require(`@/assets/images/service/${service.image}`)" alt="Card Images">
                                                </router-link>
                                            </div>
                                            <div class="content">
                                                <h4 class="title mb--20">
                                                    <router-link to="#">
                                                        {{ service.title }}
                                                    </router-link>
                                                </h4>
                                                <p class="description b1 color-gray mb--0">
                                                    {{ service.description }}
                                                </p>
                                                <router-link class="btn-default btn-small" to="#">
                                                    Read More
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SectionTitle from '../sectionTitle/SectionTitle'
    export default {
        name: 'Tab',
        components: {SectionTitle},
        props: {
            tabData: {},
            tabStyle: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                activeTab: 0
            }
        }
    }
</script>