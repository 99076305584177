<template>
    <div>
        <div class="rwt-gallery-area mb--20" v-for="(item,index) in galleryData" v-if="activeTab==0 || activeTab==index+1">
            <div class="container">
                <Gallery :gallery-data="item" column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" data-aos="fade-up"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Gallery from "../gallery/Gallery2"
    import axios from 'axios'

    export default {
        name: 'Portfolio',
        components: {Icon,Gallery},
        props: {
            portfolioData: {},
            buttonClass: {
                type: String,
                default: 'btn btn-default btn-large btn-icon'
            },
            column: {
                type: String,
                default: 'col-lg-6 col-md-6 col-sm-12 col-12 mt--30'
            }
        },
        data() {
            return {
                filters: [],
                galleryData: [[
                    {
                        thumb: require('@/assets/img/0001.png'),
                        thumb: require('@/assets/img/0001.png'),
                        content: '关于我们>>',
                        contents: '关于我们',
                        type: 'image',
                        url: '/about',
                    },
                    {
                        thumb: require('@/assets/img/034.png'),
                        thumb: require('@/assets/img/034.png'),
                        content: '业务中心>>',
                        contents: '业务中心',
                        type: 'image',
                        url: '/portfolio',
                    },
                    {
                        thumb: require('@/assets/img/056.png'),
                        thumb: require('@/assets/img/056.png'),
                        content: '客户案例>>',
                        contents: '客户案例',
                        type: 'image',
                        url: '/case',
                    },
                    {
                        thumb: require('@/assets/img/059.png'),
                        thumb: require('@/assets/img/059.png'),
                        content: '新闻资讯>>',
                        contents: '新闻资讯',
                        type: 'image',
                        url: '/news',
                    },
                    {
                        thumb: require('@/assets/img/151.png'),
                        thumb: require('@/assets/img/151.png'),
                        content: '业务合作>>',
                        contents: '业务合作',
                        type: 'image',
                        url: '/cooperate',
                    },
                    {
                        thumb: require('@/assets/img/152.png'),
                        thumb: require('@/assets/img/152.png'),
                        content: '联系我们>>',
                        contents: '联系我们',
                        type: 'image',
                        url: '/contact',
                    }
                    ]],
                activeFilter: '',
                activeTab: 0
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                // axios.get(`https://inside.tongjizhiyi.com/index.php?s=/api/passport/get_index_menus`).then(
				// 	response => {
                //         if(response.data.status==200){
                //             this.galleryData = response.data.data.galleryData
                //         }else{
                //             this.result = response.data.message
                //         }
				// 	},
				// 	error => {
				// 		//请求后更新List的数据
				// 	}
				// )
            }

        }
    }
</script>