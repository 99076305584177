<template>
    <div class="about-area about-style-4 rn-section-gap">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <SectionTitle
                        text-align="center"
                        subtitle="时代背景"
                        data-aos="fade-up"
                    />
                </div>
            </div>
            <div class="row row--40 align-items-center">
                <div class="col-lg-6">
                    <Video :video-data="videoData" column="col-12"/>
                </div>
                <div class="col-lg-6 mt_md--40 mt_sm--40">
                    <div class="content">
                        <div class="inner">
                            <ul class="feature-list">
                                <li v-for="item in dataList">
                                    <Icon name="check" size="20" icon-class="icon"/>
                                    <div class="title-wrapper">
                                        <h4 class="title">{{ item.title }}</h4>
                                        <p class="text">
                                            {{ item.description }}
                                        </p>
                                    </div>
                                </li>
                                <!-- <li>
                                    <Icon name="check" size="20" icon-class="icon"/>
                                    <div class="title-wrapper">
                                        <h4 class="title">智慧医疗作为新基建未来十大场景之一，未来可期！</h4>
                                        <p class="text">
                                            新型基础设施，成为数字经济的主要载体，成为数字时代的新结构性力量，是推动全要素数字化转型、实现全要素生产率提升的强有力支撑。这是一场伟大的数字革命。
                                        </p>
                                    </div>
                                </li> -->
                            </ul>
                            <!-- <div class="about-btn mt--30">
                                <router-link class="btn-default" to="/about">About Our Doob</router-link>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Video from "../video/Video"
    import SectionTitle from '../sectionTitle/SectionTitle'

    export default {
        name: 'AboutFour',
        components: {Video, Icon,SectionTitle},
        props: {
            video: '',
            image: '',
            dataList: {},
        },
        data() {
            return {
                videoData: [
                    {
                        thumb: this.image,
                        src: this.video,
                        // 'https://inside.tongjizhiyi.com/uploads/xuanchuan.mp4',
                    }
                ]
            }
        }
    }
</script>