<template>
    <div>
        <div class="rwt-gallery-area rn-section-gapBottom">
            <div class="row mt_dec--30 row--24">
                <swiper ref="mySwiper" :options="swiperOptions">
                    <swiper-slide v-for="(item,index) in galleryData">
                        <div class="reason-item">
                            <img @click="imageIndex = index" :src="item.src" alt="" class="deck-img" width="100%">
                        </div>
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
            </div>
        </div>
        <CoolLightBox
            :items="galleryData"
            :index="imageIndex"
            @close="imageIndex = null">
        </CoolLightBox>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Button from '../button/Button'

    export default {
        name: 'Gallery',
        components: {Button, Icon},
        props: {
            galleryData: {},
            column: {
                type: String,
                default: 'col-lg-2 col-md-6 col-24 mt--30'
            }
        },
        data() {
            return {
                imageIndex: null,
                swiperOptions: {
                    // pagination: {
                    //     el: '.swiper-pagination',    //与slot="pagination"处 class 一致
                    //     clickable: true,            //轮播按钮支持点击
                    // },
                    // //自动播放
					// autoplay: {
					// 	delay: 4000,
					// 	disableOnInteraction: false
					// },
					// //循环
					// loop:true,
                    // navigation: {
                    //     nextEl: '.swiper-button-next',
                    //     prevEl: '.swiper-button-prev',
                    // },
                    effect : 'coverflow',
                    slidesPerView: 4,
                    centeredSlides: true,
                    loop:true,
                    coverflow: {
                        rotate: 3,
                        stretch: 20,
                        depth: 10,
                        modifier: 1,
                        slideShadows : true
                    },
                    pagination: {
                        el: '.swiper-pagination',    //与slot="pagination"处 class 一致
                        clickable: true,            //轮播按钮支持点击
                    },
                    autoplay: {
						delay: 2000,
						disableOnInteraction: false
					},
                    // pagination : '.swiper-pagination'
                },
            }
        }
    }
</script>