<template>
    <div>
        <div class="row mt_dec--30 row--15">
            <div :class="column"
                 v-for="(gallery, index) in galleryData"
                 :key="index">
                
                <div class="rn-gallery2 icon-center" data-aos="fade-up" style="width:82%;margin:auto;">
                    <div class="thumbnail">
                        <img class="radius-small"
                            v-if="gallery.thumb"
                            :src="gallery.thumb"
                            alt="Video Thumbnail"/>
                        <div class="video-icons" v-html="gallery.contents"></div>
                    </div>
                    <router-link :to="gallery.url">
                        <div class="video-icon" v-html="gallery.content">
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <CoolLightBox
            :items="galleryData"
            :index="imageIndex"
            @close="imageIndex = null">
        </CoolLightBox>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'
    import Button from '../button/Button'

    export default {
        name: 'Gallery',
        components: {Button, Icon},
        props: {
            galleryData: {},
            column: {
                type: String,
                default: 'col-lg-6 col-md-6 col-12 mt--30'
            }
        },
        data() {
            return {
                imageIndex: null
            }
        },
    }
</script>
<style>
.video-icon{
    /* font-size: 18px; */
    /* width: 100%;
    height:100%;
    background-color: #ccc;
    opacity: 0.8 !important; */
}
.video-icons{
    /* font-size:18px; */
    color:var(--color-zhutise10);
    position: absolute;
    bottom:0px;
    left:10px;
    /* color:#fff; */
}
</style>