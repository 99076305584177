<template>
    <Layout>

        <div class="slider-area slider-style-1 height-850 bg_image"
             data-black-overlay="1"
             :style="{'background-image': `url(${require(`@/assets/img/khal.png`)})`}">
            <div>
            </div>
        </div>

        <Separator />

        <div class="mt--50">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="部分客户案例"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <Case class="mt--20" :portfolio-data="portfolioItems"/>
            </div>
        </div>
        <Separator />

        
        <!-- Start Brand Area  -->
        <div class="rwt-brand-area mb--50 mt--40">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="部分药房实景"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Gallery :gallery-data="robotData" column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Brand Area  -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Separator from '../components/elements/separator/Separator'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Case from '../components/elements/case/Case'
    import PortfolioItemMixin from '../mixins/PortfolioItemMixin'
    import Brand from "../components/elements/brand/Brand";
    import Gallery from "../components/elements/gallery/Gallery4";
    import axios from 'axios'

    export default {
        name: 'PortfolioPage',
        components: {Case, SectionTitle, Layout, Breadcrumb,Brand,Gallery,Separator},
        mixins: [PortfolioItemMixin],
        data() {
            return {
                brandList2: [
                    {
                        // image: require(`@/assets/images/brand/brand-01.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-02.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-03.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-01.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-02.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-03.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-01.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-02.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-03.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-01.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-02.png`),
                        text:'贵阳市第一人民医院'
                    },
                    {
                        // image: require(`@/assets/images/brand/brand-03.png`),
                        text:'贵阳市第一人民医院'
                    },
                ],
                robotData: [],
            }
        },
        created() {
            this.init()
        },
        methods: {
            init() {
                setTimeout(() => {
                    axios.get(`https://inside.tongjizhiyi.com/index.php?s=/api/passport/get_case`).then(
                        response => {
                            if(response.data.status==200){
                                this.robotData = response.data.data.robot
                                console.log(this.robotData)
                            }
                        },
                        error => {
                            //请求后更新List的数据
                        }
                    )
                }, 800);
            }

        }
    }
</script>
<style scoped>
@keyframes wrapper-gradient {
        100% {
            transform: translateY(0);
        }
        0% {
            transform: translateY(100%);
        }
    }
    .bg_image {
      animation: wrapper-gradient 0.5s linear;
    }
</style>