<template>
    <div class="copyright-area copyright-style-one variation-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div class="copyright-left">
                        <ul class="ft-menu link-hover">
                            <li><router-link to="/about">关于我们</router-link></li>
                            <li><router-link to="/portfolio">业务中心</router-link></li>
                            <li><router-link to="/case">客户案例</router-link></li>
                            <li><router-link to="/news">新闻资讯</router-link></li>
                            <li><router-link to="/cooperate">业务合作</router-link></li>
                            <li><router-link to="/contact">联系我们</router-link></li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-5 mt_sm--20">
                    <div class="copyright-center text-center">
                        <ul class="social-icon social-default color-lessdark justify-content-center">
                            <li><a href="https://www/facebook.com/" target="_blank"><Icon name="facebook"/></a></li>
                            <li><a href="https://www/twitter.com/" target="_blank"><Icon name="twitter"/></a></li>
                            <li><a href="https://www/instagram.com/" target="_blank"><Icon name="instagram"/></a></li>
                            <li><a href="https://www/linkedin.com/" target="_blank"><Icon name="linkedin"/></a></li>
                        </ul>
                    </div>
                </div> -->
                <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                    <div class="copyright-right text-center text-lg-end">
                        ©{{ new Date().getFullYear() }} 通济智医 <a href="https://beian.miit.gov.cn/" style="color:#555;" target="_blank">黔ICP备2023002414号</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Icon from '../../icon/Icon'

    export default {
        name: 'CopyrightTwo',
        components: {Icon}
    }
</script>